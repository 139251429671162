import TermsValidator from "../../../../_LEGACY/domain/TermsValidator";
import Validator from "../../../../_LEGACY/domain/Validator";
import { dateFromThePast } from "../helpers/dateFromThePast";

class OrderValidator extends Validator {
  validateOffer = (offer) => {
    if (!offer.name) this.errors.add("Укажите предложение");
    if (!offer.price) this.errors.add("Укажите цену");

    offer.supplies.forEach((supply) => {
      if (!supply.count) this.errors.add("Укажите количество");
      if (!supply.date) this.errors.add("Укажите дату");
      //if (dateFromThePast(supply.date)) this.errors.add("Укажите корректную дату");
    });
  };

  validateRequestOrKit = (requestOrKit) => {
    if (requestOrKit.offers.length === 0) this.errors.add("Укажите предложения к позициям");
    requestOrKit.offers.forEach(this.validateOffer);
  };

  validate = (order) => {
    if (!order || !order.body) return false;
    this.errors.clear();

    order.body.requests.forEach(this.validateRequestOrKit);
    order.body.kits.forEach(this.validateRequestOrKit);

    const termsValidator = new TermsValidator(order.payment_terms, order.delivery_terms);
    termsValidator.validate();

    if (this.errors.size !== 0 || termsValidator.errors.size !== 0) {
      this.onError([...this.errors, ...termsValidator.errors]);
      return false;
    }

    return true;
  };
}

export default OrderValidator;
