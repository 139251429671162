import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { apiLoadProductsSection } from "redux/modules/common/building/processApi";
import { loadSections } from "redux/modules/common/building/sections/sections";
import { apiLoadSections } from "redux/modules/common/building/sections/sectionsApi";

import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import {
  createOutOfEstimateSectionActions,
  createOutOfEstimateSectionSelectors,
} from "features/CreateOutOfEstimateSection";

import { TabsEnum } from "../enums";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const _TEMP_useBuildingPageOutOfEstimate = ({
  selectedSection,
  isLoadingSubSections,
  activeTabId,
  setSelectedSection,
  editable,
}: {
  isLoadingSubSections: boolean;
  selectedSection: any;
  activeTabId: any;
  setSelectedSection: any;
  editable: boolean;
}) => {
  const dispatch = useDispatch();
  const { objectId } = useTypedParams();

  const isCreateOutOfEstimateSectionVisibleRaw = useSelector(createOutOfEstimateSectionSelectors.getIsVisible);
  const isCreateOutOfEstimateSectionVisible = !isLoadingSubSections && isCreateOutOfEstimateSectionVisibleRaw;

  const onAddOutOfEstimateClick = useCallback(() => {
    dispatch(createOutOfEstimateSectionActions.open({ parentId: selectedSection?.id }));
  }, [selectedSection]);

  useEffect(() => {
    if (activeTabId !== TabsEnum.plan) {
      dispatch(createOutOfEstimateSectionActions.clear());
    }
  }, [activeTabId]);

  const onCreateOutOfEstimateSectionCallback = useCallback(async () => {
    //ну просто пиздец
    if (selectedSection) {
      // уровень подразделов, перезагружаем их
      const section = await apiLoadProductsSection(+objectId, selectedSection.id, editable);

      await apiLoadSections(objectId, ESTIMATE_STATES_IDS.PRODUCTION, {
        parent: selectedSection.id,
      })
        .then((responseData) => setSelectedSection(() => ({ ...section, subsections: responseData?.results })))
        .catch(errorCatcher);
    } else {
      // верхний уровень, перезагружаем секции
      compose<any>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, objectId });
    }
  }, [objectId, selectedSection]);

  const canAddOutOfEstimate = activeTabId === TabsEnum.plan;

  return {
    onAddOutOfEstimateClick,
    isCreateOutOfEstimateSectionVisible,
    onCreateOutOfEstimateSectionCallback,
    canAddOutOfEstimate,
  };
};
