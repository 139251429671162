import React, { useEffect, useMemo, useState } from "react";

import { apiLoadProductsSection } from "redux/modules/common/building/processApi";
import { apiLoadSections } from "redux/modules/common/building/sections/sectionsApi";

import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import { IbuildingSubsection } from "../types";

import { useTypedParams } from "utils/hooks/useTypedParams";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const _TEMP_useBuildingPageSubSections = ({
  selectedSection,
  sections,
  isNeedToOpenPlan,
  openedParentId,
  editable,
  setSelectedSection,
  openSectionId,
  setOpenedParentId,
}: {
  selectedSection?: IbuildingSubsection | null;
  sections: any;
  isNeedToOpenPlan: any;
  openedParentId: any;
  editable: boolean;
  setSelectedSection: any;
  openSectionId: any;
  setOpenedParentId: any;
}) => {
  const { objectId } = useTypedParams();
  const subSectionsForDisplay = useMemo(() => {
    if (!selectedSection?.subsections) return [];
    return selectedSection.subsections
      .filter((el) => !el.hidden_status)
      .sort((a, b) => {
        if (a.name === "Вне сметы") return 1;
        return -1;
      });
  }, [selectedSection]);

  useEffect(() => {
    if (sections.length && isNeedToOpenPlan && !openedParentId) {
      loadSubSection(+openSectionId!);
      setOpenedParentId(+openSectionId!);
    }
  }, [isNeedToOpenPlan, sections]);

  const [isLoadingSubSections, setIsLoadingSubSections] = useState(false);

  const loadSubSection = async (subSectionId: number) => {
    setIsLoadingSubSections(true);
    await loadAndSetSubSection(subSectionId);
    setIsLoadingSubSections(false);
  };

  const loadAndSetSubSection = async (subSectionId: number) => {
    const section = await apiLoadProductsSection(+objectId, subSectionId, editable);

    await apiLoadSections(objectId, ESTIMATE_STATES_IDS.PRODUCTION, {
      parent: subSectionId,
    })
      .then((responseData) => setSelectedSection(() => ({ ...section, subsections: responseData?.results })))
      .catch(errorCatcher);
  };

  return {
    subSectionsForDisplay,
    loadAndSetSubSection,
    loadSubSection,
    isLoadingSubSections,
  };
};
