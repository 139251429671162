import { message } from "antd";
import axios from "axios";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { loadObjectsBreadcrumbs } from "redux/modules/common/building/objects";
import { loadSections } from "redux/modules/common/building/sections/sections";

import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import { sharingBarExistingChecker, sharingBarExistingCheckerPropsType } from "../utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const _TEMP_useBuildingPageSharing = ({
  buildingId,
  selectedSection,
  loadAndSetSubSection,
  permissions,
  activeTabId,
  userInfo,
  objectInfo,
}: {
  buildingId: string;
  selectedSection: any;
  loadAndSetSubSection: any;
  permissions: any;
  activeTabId?: string;
  userInfo: any;
  objectInfo: any;
}) => {
  const dispatch = useDispatch();
  const [isShowSharedButtons, setIsShowSharedButtons] = useState(true);

  const sharingCallback = useCallback(async () => {
    if (!selectedSection?.id) {
      compose<any>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
    } else {
      await loadAndSetSubSection(selectedSection.id);
    }
  }, [selectedSection?.id, buildingId]);

  const approveSharingHandler = () => {
    axios
      .post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "accepted" })
      .then(() => {
        dispatch(loadObjectsBreadcrumbs());
        setIsShowSharedButtons(false);
        message.success("Успешно принято");
      })
      .catch(errorCatcher);
  };

  const declineSharingHandler = () => {
    axios
      .post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "canceled" })
      .then(() => {
        setIsShowSharedButtons(false);
        message.success("Успешно отклонено");
      })
      .catch(errorCatcher);
    //
  };

  const isShared = useMemo(
    () => Boolean(objectInfo?.is_shared && ["transferred"].includes(objectInfo?.shared_status!)),
    [objectInfo?.is_shared, objectInfo?.shared_status]
  );

  const canSharing = useMemo(() => {
    if (userInfo.tariff === "BASIC") return false;
    return sharingBarExistingChecker({
      isShared,
      haveSharingPlanPermission: permissions.canSharePlan,
      haveSharingProgressPermission: permissions.canShareProgress,
      activeTab: activeTabId as sharingBarExistingCheckerPropsType["activeTab"],
    });
  }, [isShared, permissions, activeTabId, userInfo]);

  return {
    sharingCallback,
    approveSharingHandler,
    declineSharingHandler,
    isShowSharedButtons,
    canSharing,
    isShared,
  };
};
