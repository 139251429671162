import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createExpendituresGroup, isUpdateGroupSuccessSelector } from "redux/modules/common/building/shedules";

import { useTypedSelector } from "app/store/typedUseSelector";

import { TabsEnum } from "../enums";
import { ICreateGroupValues } from "../types";
import { VIEW_BUILDING_ADD_GROUPS } from "constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_PROJECT_ADD_GROUPS } from "constants/permissions/manufacturingPermissions";

import { useCommonModulesPermissions } from "hooks/useCommonModulesPermissions";
import { useTypedParams } from "utils/hooks/useTypedParams";

export const _TEMP_useBuildingPageGroups = ({
  selectedProducts,
  activeTabId,
}: {
  selectedProducts: any;
  activeTabId: any;
}) => {
  const dispatch = useDispatch();
  const { objectId } = useTypedParams();
  const isUpdateGroupSuccess = useSelector(isUpdateGroupSuccessSelector);
  const [isShowGroupModal, setIsShowGroupModal] = useState(false);

  const handleCloseGroupModal = useCallback(() => {
    setIsShowGroupModal(false);
  }, []);

  const handleOpenCreateGroupModal = useCallback(() => {
    setIsShowGroupModal(true);
  }, []);

  useEffect(() => {
    if (!isUpdateGroupSuccess) return;

    setIsShowGroupModal(false);
  }, [isUpdateGroupSuccess]);

  const handleCreateGroup = useCallback(
    (values: ICreateGroupValues) => {
      dispatch(createExpendituresGroup(objectId, values));
    },
    [objectId, selectedProducts]
  );

  const isGroupingPending = useTypedSelector((state) => state.shedules.groupingPending);

  const haveAddGroupsPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_PROJECT_ADD_GROUPS,
    constructing: VIEW_BUILDING_ADD_GROUPS,
  });

  const canGroup = useMemo(() => {
    return haveAddGroupsPermission && activeTabId === TabsEnum.plan;
  }, [activeTabId, haveAddGroupsPermission]);

  return {
    handleOpenCreateGroupModal,
    handleCloseGroupModal,
    isGroupingPending,
    handleCreateGroup,
    canGroup,
    isShowGroupModal,
  };
};
