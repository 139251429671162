import cn from "classnames";
import { memoize } from "lodash";
import moment from "moment";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ISectionPlan } from "../../../../../redux/modules/common/building/processTypes";
import {
  deleteDefaultWorkAndRelatedResourcesInHandler,
  expendituresBySectionsSelector,
  expendituresInvalidateKeySelector,
} from "../../../../../redux/modules/common/building/sections/sections";
import { userSelector } from "redux/modules/_TODO/auth";
import { expendituresGroupsBySectionsSelector } from "redux/modules/common/building/shedules";

import { useUIIndicator } from "../../../../../hooks/uiIndicators/useUIIndicator";
import { ESTIMATE_SECTION_VARIANTS } from "../../../../UI/_TODO/EstimateSection/EstimateSection";
import { AMOUNTS_VARIANTS } from "../../../../UI/_TODO/EstimateSection/components/Amounts/Amounts";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import AddExpenditures from "pages/Handler/ui/ProHandler/components/AddExpenditure/AddExpenditures";
import { IExpenditureInHandlerProduction } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/types";
import {
  groupHandlerExpendituresForEdit,
  prepareWorkAndRelatedResourcesForDeleting,
} from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/utils";

import BuildingSection from "../BuildingSection/BuildingSection";
import BuildingExpenditure from "../Expenditure/BuildingExpenditure";
import SectionPlanModal from "../SectionPlanModal/SectionPlanModal";
import { IPlanSection } from "./useSectionPlan";
import Actions from "shared/ui/controls/Actions/Actions";

import ISection from "../../../../../types/interfaces/Section";
import { TabsEnum } from "../../enums";
import { IExpenditureTypesFiltersInBuilding } from "../../types";

import { _DEPRECATED_useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

import { stopEventPropagation } from "../../../../../utils/helpers/stopEventPropagation";
import { stringifyArgs } from "../../../../../utils/helpers/stringifyArgs";
import { sortBuildingExpendituresAndGroups } from "./utils/sortExpendituresAndGroups";

import styles from "./SubSection.module.scss";

export interface IExternalOffsetsSubsection {
  subsection?: number;
  products?: number;
}

interface IProps {
  filters: IExpenditureTypesFiltersInBuilding;
  objectId: number;
  section: ISection;
  measure: any;
  activeTab: TabsEnum;
  loadProduct: () => void;
  isShared: boolean;
  isIndicatorsOpen: boolean;
  canSharing: boolean;
  permissions: {
    canAddFiles: boolean;
    canDeleteFiles: boolean;
    canAddPlan: boolean;
    canAddProgress: boolean;
  };
  onCheck: (isChecked: boolean, itemId: number, expenditure: any | undefined) => void;
  canCheck?: boolean;
  checkedExpenditures: any;
  checkedSections: any;
  intervals: any;
  expendituresOverview: any;
  sectionPlanData: ISectionPlan;
  isShownPlanData: boolean;
  onReloadSubSections: () => void;
  canAddPlan?: boolean;
  loadSectionExpenditures: () => void;
  loadSectionGroups: () => void;
  parentId: number;
  lsrPlanData?: IPlanSection;
  dateStart: string;
  dateEnd: string;
  isDefaultOpened?: boolean;
  onAddBtnClick?: () => void;
  isCheckboxPlaceholder?: boolean;
  isDisplayAddButton?: boolean;
  dataTestId?: string | number;
  externalTopOffsets?: IExternalOffsetsSubsection;
  onRefetch?: () => void;
}

const SubSection: FC<IProps> = ({
  objectId,
  section,
  measure,
  activeTab,
  loadProduct,
  isShared,
  isIndicatorsOpen,
  canSharing,
  permissions,
  onCheck,
  canCheck,
  checkedExpenditures,
  checkedSections,
  intervals,
  expendituresOverview,
  sectionPlanData,
  isShownPlanData,
  onReloadSubSections,
  isCheckboxPlaceholder,
  isDisplayAddButton,
  onAddBtnClick,
  canAddPlan,
  loadSectionExpenditures,
  loadSectionGroups,
  dataTestId,
  parentId,
  lsrPlanData,
  dateStart,
  dateEnd,
  filters,
  isDefaultOpened = false,
  externalTopOffsets,
  onRefetch,
}) => {
  const [isActive, setIsActive] = useState(isDefaultOpened);
  const buildingId = useObjectId();

  const dispatch = useDispatch();

  const canShareSection = canSharing && !section.providers?.length;

  const expendituresBySections = useSelector(expendituresBySectionsSelector); /* @ts-ignore */
  const expenditures = expendituresBySections[section.id]?.results || [];

  const expendituresGroupsBySections = useSelector(expendituresGroupsBySectionsSelector);
  const expendituresGroups = expendituresGroupsBySections[section.id]?.groups || [];
  const expendituresGroupsInvalidateKey = expendituresGroupsBySections[section.id]?.invalidateKey;

  const expenditureInvalidateKey = useSelector(expendituresInvalidateKeySelector);

  const handleClick = () => {
    setIsActive((prevState) => !prevState);
  };

  useEffect(() => {
    if (!isActive) return;
    setDeletingResourcesCount(0);
    loadSectionExpenditures?.();
    loadSectionGroups?.();
  }, [isActive, loadSectionExpenditures, loadSectionGroups, expendituresGroupsInvalidateKey, expenditureInvalidateKey]);

  const subSectionStyle = useMemo(() => {
    if (externalTopOffsets?.subsection !== undefined) return { top: `${externalTopOffsets?.subsection}rem` };
    let topOffset = 14.6;
    if (!canShareSection) topOffset = topOffset - 3;
    if (!isIndicatorsOpen) topOffset = topOffset - 6.3;
    return { top: `${topOffset}rem` };
  }, [canShareSection, isIndicatorsOpen, externalTopOffsets]);

  const productHeaderStyle = useMemo(() => {
    if (externalTopOffsets?.products !== undefined) return { top: `${externalTopOffsets?.products}rem` };
    let topOffset = 17.8;
    if (!canShareSection) topOffset = topOffset - 3;
    if (!isIndicatorsOpen) topOffset = topOffset - 6.3;
    return { top: `${topOffset}rem` };
  }, [canShareSection, isIndicatorsOpen, externalTopOffsets]);

  const onCheckExpenditure = useMemo(
    () =>
      memoize(
        /* @ts-ignore */
        (expenditureId, expenditureEstimateAmount) => (isChecked) =>
          onCheck(isChecked, expenditureId, expenditureEstimateAmount),
        stringifyArgs
      ),
    [onCheck]
  );
  /* @ts-ignore */
  const onCheckSubSection = useCallback((isChecked) => onCheck(isChecked, section.id), [onCheck, section.id]);

  const amounts = useMemo(
    () => ({
      all: section?.indicators ? section.indicators.estimate_amount : 0,
      completed: section?.indicators ? section.indicators.work_completed : 0,
      accepted: section?.indicators ? section.indicators.amount_accepted : 0,
      invested: section?.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.INLINE,
      measure /* @ts-ignore */,
      sumEstimateAmount: section.aggregation_data?.sum_estimate_amount ?? 0,
    }),
    [section?.indicators, measure]
  );

  const [isSectionPlanModalOpen, setIsSectionPlanModalOpen] = React.useState(false);

  const sectionPlanModalHandler = React.useCallback(
    /* @ts-ignore */
    (e) => {
      e?.stopPropagation?.();
      if (onAddBtnClick) {
        onAddBtnClick();
      } else {
        setIsSectionPlanModalOpen((prev) => !prev);
      }
    },
    [onAddBtnClick]
  );

  const sectionModalCloseHandler = React.useCallback(() => {
    /* @ts-ignore */
    onReloadSubSections?.(section.parent_id);
    setIsSectionPlanModalOpen(false);
  }, [onReloadSubSections, section.parent_id]);

  const [isNeedToOpenSectionPlan, setIsNeedToOpenPlanSectionModal] = React.useState(false);
  const openSectionPlan = () => {
    setIsNeedToOpenPlanSectionModal(true);
    setTimeout(() => {
      setIsNeedToOpenPlanSectionModal(false);
    }, 100);
  };

  const sortedExpendituresAndGroups = useMemo(() => {
    return sortBuildingExpendituresAndGroups(expenditures, expendituresGroups, filters);
  }, [expenditures, expendituresGroups, filters]);

  const { isIndicatorDisplayed } = useUIIndicator({
    /* @ts-ignore */
    type: "confirm_expenditure_plan",
    data: { child_section_id: section.id, parent_section_id: section.parent_id },
    customFilter: (indicator) =>
      !!indicator?.data?.year &&
      !!indicator?.data?.month &&
      moment()
        .year(indicator.data.year)
        .month(indicator.data.month - 1)
        .date(1)
        .isBetween(moment(dateStart), moment(dateEnd)),
  });

  const canAddExpenditure = section.is_default && isActive && activeTab === TabsEnum.plan;

  const [addedExpenditures, setAddedExpenditures] = useState<number[]>([]);

  const [editingExpenditures, setEditingExpenditures] = useState<number[]>([]);

  const onEdit = (id: number) => {
    /* @ts-ignore */
    const exp = sortedExpendituresAndGroups?.find((el) => el.id === +id);
    if (!exp) return; /* @ts-ignore */
    if (exp.expenditure_type === "work") {
      setEditingExpenditures((p) => [...p, id]);
    } else {
      /* @ts-ignore */
      !!exp.related_work?.id && setEditingExpenditures((p) => [...p, exp.related_work.id]);
    }
  };

  const onCancelEdit = (id: number) => {
    /* @ts-ignore */
    const exp = sortedExpendituresAndGroups?.find((el) => el.id === +id);
    if (!exp) return; /* @ts-ignore */
    const findingId = exp.expenditure_type === "work" ? id : exp.related_work.id;
    setEditingExpenditures((p) => p.filter((el) => el !== findingId));
  };

  const expendituresAbleToEdit = useMemo(() => {
    if (!section.is_default) {
      return sortedExpendituresAndGroups;
    }
    return groupHandlerExpendituresForEdit(sortedExpendituresAndGroups, editingExpenditures);
  }, [sortedExpendituresAndGroups, editingExpenditures, section?.is_default]);

  const [deletingResourcesCount, setDeletingResourcesCount] = useState(0);

  const removeWorkAndRelatedResources = (expenditure: IExpenditureInHandlerProduction) => {
    const relatedResources = prepareWorkAndRelatedResourcesForDeleting(expenditure?.id, expendituresAbleToEdit);
    setDeletingResourcesCount(0);
    dispatch(deleteDefaultWorkAndRelatedResourcesInHandler(objectId!, expenditure?.id, relatedResources));
  };

  const reCountDeletingResources = (expId: number) => {
    const relatedResources = prepareWorkAndRelatedResourcesForDeleting(expId, expendituresAbleToEdit);
    setDeletingResourcesCount(relatedResources.length);
  };

  const user = useSelector(userSelector);
  const { buildingData: building } = _DEPRECATED_useBuildingDetailDataById(String(objectId));
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;
  const canEditEstimate = section.is_default && userIsResponsibleEmployee && activeTab === TabsEnum.plan;

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isActive,
        [styles.withIndicator]: !isActive && isIndicatorDisplayed,
      })}
      data-testid={dataTestId}
    >
      <div
        className={cn(styles.titleContainer, { [styles.active]: isActive })}
        style={subSectionStyle}
        onClick={handleClick}
        data-testid="sectionActiveTrigger"
      >
        <BuildingSection
          activeTab={activeTab}
          section={section}
          isChecked={checkedSections?.includes(section.id)}
          onCheck={onCheckSubSection} /* @ts-ignore */
          amounts={amounts}
          variant={isActive ? ESTIMATE_SECTION_VARIANTS.secondary : ESTIMATE_SECTION_VARIANTS.primary}
          canCheck={!isShared && !isActive && canShareSection}
          isDisplayAddButton={isDisplayAddButton || canAddPlan} /* @ts-ignore */
          onAddButtonClick={sectionPlanModalHandler}
          isDisplayEstimateStateMark
          isDisplayAmounts
          sectionPlanData={sectionPlanData}
          isShownPlanData={isShownPlanData}
          isCheckboxPlaceholder={isCheckboxPlaceholder}
          onRefetchSectionPlan={onReloadSubSections}
          isNeedOpenSectionPlan={isNeedToOpenSectionPlan}
          canAddExpenditure={canAddExpenditure}
          onRefetch={onRefetch}
        />
      </div>
      {isActive && (
        <>
          <div className={cn(styles.header)} style={productHeaderStyle}>
            <div className={styles.numberCol}>№</div>
            <div className={styles.nameCol}>Наименование</div>
            <div className={styles.countCol}>Количество</div>
            <div className={styles.addIntervalCol} />
            <div className={styles.intervalCol} data-testid="sectionFirstIntervalCol">
              {activeTab === TabsEnum.progress ? "В производстве" : "План"}
            </div>
            <div className={styles.intervalCol} data-testid="sectionSecondIntervalCol">
              {activeTab === TabsEnum.progress ? "Выполнено" : "Согласовано"}
            </div>
            <div className={styles.intervalCol} data-testid="sectionThirdIntervalCol">
              {activeTab === TabsEnum.progress ? "Принято" : "Факт"}
            </div>
            <div className={styles.actionsCol}></div>
          </div>
          {expendituresAbleToEdit.map((expenditureOrGroup) => {
            /* @ts-ignore */
            if (expenditureOrGroup.hidden) return;
            const isGroup = !expenditureOrGroup.expenditure_type;
            return (
              <BuildingExpenditure
                activeTab={activeTab}
                buildingId={objectId}
                expenditureOrGroup={
                  /* @ts-ignore */
                  expendituresOverview?.[objectId]?.find((el) => el.id === expenditureOrGroup.id) || expenditureOrGroup
                }
                loadProduct={loadProduct}
                tab={activeTab}
                isShared={isShared}
                onCheck={onCheckExpenditure(expenditureOrGroup.id, expenditureOrGroup)}
                isChecked={
                  /* @ts-ignore */
                  checkedExpenditures && checkedExpenditures?.findIndex((x) => x.id === expenditureOrGroup.id) !== -1
                } /* @ts-ignore */
                canCheck={!isShared && !!!expenditureOrGroup.providers?.length && canCheck}
                sectionName={section.name}
                intervals={isGroup ? undefined : intervals?.[expenditureOrGroup.id]}
                actions={[]}
                permissions={isGroup ? undefined : permissions}
                key={expenditureOrGroup.id}
                factIntervals={
                  isGroup /* @ts-ignore */
                    ? expenditureOrGroup.fact_intervals?.map((item) => ({
                        ...item,
                        week: moment(item?.start_at, "YYYY-MM-DD").week(),
                      }))
                    : undefined
                }
                planIntervals={
                  isGroup /* @ts-ignore */
                    ? expenditureOrGroup.plan_intervals?.map((item) => ({
                        ...item,
                        week: moment(item?.start_at, "YYYY-MM-DD").week(),
                      }))
                    : undefined
                }
                loadSectionExpenditures={loadSectionExpenditures}
                loadSectionGroups={loadSectionGroups}
                parentId={parentId}
                onOpenApprovedPlanModal={openSectionPlan}
                isKSHidden={isGroup}
                isExpendituresGroup={isGroup}
                canViewFiles={isGroup ? false : undefined}
                lsrPlanData={lsrPlanData}
                dateStart={dateStart}
                dateEnd={dateEnd}
                sectionId={section.id}
                sectionParentId={section.parent_id as number}
                isEditing={editingExpenditures.includes(expenditureOrGroup.id)}
                setIsEditing={onEdit}
                onCancelEdit={onCancelEdit}
                directlyAction={
                  <>
                    {isGroup && <div></div>}
                    {canEditEstimate && !isGroup ? (
                      <Actions
                        canEdit
                        onEdit={() => onEdit(expenditureOrGroup?.id)}
                        canRemove
                        onRemove={() => removeWorkAndRelatedResources(expenditureOrGroup)}
                        confirmDeleteText={
                          <span>
                            Вы действительно хотите удалить позицию?
                            <br />
                            {!!deletingResourcesCount &&
                              `Связанные ресурсы (${deletingResourcesCount} шт) тоже будут удалены.`}
                          </span>
                        }
                        onOpenConfirmModal={() => reCountDeletingResources(expenditureOrGroup?.id)}
                      />
                    ) : null}
                  </>
                }
              />
            );
          })}
          {canEditEstimate && canAddExpenditure && (
            <AddExpenditures
              buildingId={+buildingId}
              isOutOfEstimate
              sectionId={section.id}
              addedExpenditures={addedExpenditures}
              setAddedExpenditures={setAddedExpenditures}
            />
          )}
        </>
      )}
      {canAddPlan && (
        <div onClick={stopEventPropagation}>
          <SectionPlanModal
            isOpen={isSectionPlanModalOpen}
            onClose={sectionModalCloseHandler}
            sectionName={section.name}
            sectionId={section.id}
            sectionBudget={section.indicators.estimate_amount}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(SubSection);
