import axios from "axios";

import { IAosrInKs2ExpenditureListFiles } from "redux/modules/common/passportsAndCertificates/types";

import { IAosr, IAosrDetail, IAosrDoc, IAosrDocument, ICreateAosr, IEditDocsValues } from "./types";
import { IListResponseData } from "types/ListResponse";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import downloadFile from "utils/helpers/download";

export const aosrApi = {
  getAosr: (buildingId: string) => {
    return axios.get<IListResponseData<IAosr>>(`/building/${buildingId}/ticketacts/aosr/`);
  },
  createAosr: (buildingId: string, data: ICreateAosr) => {
    return axios.post(`/building/${buildingId}/ticketacts/aosr/create_draft/`, data);
  },
  createDoc: (buildingId: string, data: IAosrDocument, aosrId: number) => {
    return axios.post(`/building/${buildingId}/ticketacts/aosr/${aosrId}/docs/`, data);
  },
  editDocs: (buildingId: string, aosrId: number, data: IEditDocsValues, docId: number) => {
    return axios.patch(`/building/${buildingId}/ticketacts/aosr/${aosrId}/docs/${docId}/`, data);
  },
  deleteDocs: (buildingId: string, aosrId: number, docId: number) => {
    return axios.delete(`/building/${buildingId}/ticketacts/aosr/${aosrId}/docs/${docId}/`);
  },
  download: (buildingId: string, expenditureId: never, hiddenworkId: number, aosrName: string, extension?: string) => {
    const params = new URLSearchParams();
    params.set("extension", extension ?? "");

    downloadFile(
      `${axios.defaults.baseURL}/building/${buildingId}/ticketacts/aosr/${hiddenworkId}/download/?${params.toString()}`,
      `${aosrName}.${extension ?? "xlsx"}`
    );
  },
  editAosr: (
    buildingId: string,
    hiddenworkId: number,
    data: (Partial<ICreateAosr> & { confirmed_file: string }) | Partial<IAosrDetail>
  ) => {
    return axios.patch(`/building/${buildingId}/ticketacts/aosr/${hiddenworkId}/`, data);
  },
  deleteDocFile: (buildingId: string, expenditureId: never, aosrId: number, fileId: number) => {
    return axios.delete(`/building/${buildingId}/ticketacts/aosr/${aosrId}/files/${fileId}/`);
  },
  addDocFile: async (buildingId: string, expenditureId: never, formData: FormData, aosrId: number) => {
    return axios.post(`/building/${buildingId}/ticketacts/aosr/${aosrId}/files/`, formData);
  },
  getAosrDetail: async (buildingId: string, expenditureId: number, hiddenworkId: number) => {
    return axios.get<IAosrDetail>(`/building/${buildingId}/ticketacts/aosr/${hiddenworkId}/`);
  },
  closeAosr: (buildingId: string, aosrId: number, confirmed_file: string) => {
    return axios.post(`/building/${buildingId}/ticketacts/aosr/${aosrId}/close/`, {
      confirmed_file,
    });
  },
};
