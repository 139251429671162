import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

export const _TEMP_useBuildingPageDates = ({ isNeedToOpenPlan }: { isNeedToOpenPlan?: boolean }) => {
  const [date, setDate] = useState(() => (isNeedToOpenPlan ? moment().startOf("year") : moment()));
  const [selectingMonthsCount, setSelectingMonthsCount] = useState(() => (isNeedToOpenPlan ? 12 : 1));

  useEffect(() => {
    if (!isNeedToOpenPlan && selectingMonthsCount === 12) {
      setSelectingMonthsCount(1);
      setDate(moment());
    }
  }, [isNeedToOpenPlan]);

  const startOfMonthFormattedDate = useMemo(() => moment(date).startOf("month").format("YYYY-MM-DD"), [date]);
  const endOfMonthFormattedDate = useMemo(
    () =>
      moment(date)
        .add(selectingMonthsCount - 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    [date, selectingMonthsCount]
  );

  return {
    date,
    setDate,
    startOfMonthFormattedDate,
    endOfMonthFormattedDate,
    selectingMonthsCount,
    setSelectingMonthsCount,
  };
};
