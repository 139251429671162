import { message } from "antd";
import Axios from "axios";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  selectedProductsSelector,
  selectedSubsectionsSelector,
  shareEstimateItems,
  shareEstimateItemsIsLoadingSelector,
} from "../../../../../redux/modules/common/building/process";

import Select from "components/UI/atoms/Select";
import { IBuildingContractor, IBuildingContractorEntity } from "components/pages/Settings/EditProject/types";
import ImportDocumentation from "pages/Handler/ui/ProHandler/components/ImportExtraordinaryDocumentation/ImportDocumentation";

import { createOutOfEstimateSectionSelectors } from "../../../../../features/CreateOutOfEstimateSection";
import AddButton from "../../../../../shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { EXPENDITURE_TYPES } from "../../../../../constants/constant";
import { IExpenditureTypesFiltersInBuilding, ISelectedProduct } from "../../types";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import styles from "./ProjectSharingBar.module.scss";

export interface IProps {
  isExists: boolean;
  filters: IExpenditureTypesFiltersInBuilding | null;
  sharingCallback: () => void;
  handleOpenModal: () => void;
  canSharing: boolean;
  canGroup: boolean;
  canAddOutOfEstimate: boolean;
  onAddOutOfEstimateClick: () => void;
  isInsideSection?: boolean;
  canImport?: boolean;
  refreshSections?: any;
}

const ProjectSharingBar: React.FC<IProps> = ({
  isExists,
  filters,
  sharingCallback,
  handleOpenModal,
  canSharing,
  canGroup,
  canAddOutOfEstimate,
  onAddOutOfEstimateClick,
  isInsideSection,
  canImport,
  refreshSections,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();

  const shareEstimateItemsIsLoading = useSelector(shareEstimateItemsIsLoadingSelector);
  const selectedProducts = useSelector(selectedProductsSelector);
  const selectedSubsections = useSelector(selectedSubsectionsSelector);

  const typeOfContractors = "outer";

  const [contractors, setContractors] = useState<IBuildingContractorEntity[]>([]);

  const typeFilters = useMemo(() => {
    const res = new URLSearchParams({
      expenditure_type: EXPENDITURE_TYPES.WORK,
    });
    if (!filters) return res;
    for (const key in filters) {
      // @ts-ignore
      if (filters[key]) {
        res.append("expenditure_type", key);
      }
    }
    return res;
  }, [filters]);

  const formattedContractors = useMemo(() => {
    return contractors?.map((el) => ({
      id: el.id,
      name: el.name,
    }));
  }, [contractors, typeOfContractors]);

  const selectedCount = useMemo(() => {
    return selectedSubsections.length + selectedProducts.length;
  }, [selectedProducts, selectedSubsections]);

  const isGroupButtonDisabled = useMemo(
    () =>
      selectedSubsections.length !== 0 ||
      selectedProducts.length === 0 ||
      selectedProducts?.some((item: ISelectedProduct) => selectedProducts[0]?.sectionId !== item?.sectionId),
    [selectedSubsections, selectedProducts]
  );

  useEffect(() => {
    Axios.get(`/building/${objectId}/contractors/`).then((r) =>
      setContractors(r.data.results?.map((el: IBuildingContractor) => ({ ...el.entity })))
    );
  }, [objectId, typeOfContractors]);

  const selectSubmitHandler = (id: number) => {
    dispatch(shareEstimateItems(objectId, id, typeFilters, sharingCallback));
  };

  const emptyClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!formattedContractors?.length) {
      message.error("Сначала добавьте подрядчика");
    }
  };

  if (!isExists) return null;

  return (
    <div className={cn(styles.bar, { [styles.activeBar]: selectedCount })}>
      {(canSharing || canGroup) && (
        <span>
          Выбрано разделов:{" "}
          {selectedCount ? <span className={styles.selectedCount}>{selectedSubsections.length}</span> : "-"}
        </span>
      )}
      {(canSharing || canGroup) && (
        <span>
          Выбрано записей:{" "}
          {selectedCount ? <span className={styles.selectedCount}>{selectedProducts.length}</span> : "-"}
        </span>
      )}
      <div>
        {canImport && refreshSections && (
          <ImportDocumentation refreshSections={refreshSections} className={styles.import} />
        )}
        {canAddOutOfEstimate && (
          <div className={styles.addOutOfEstimateButton} onClick={onAddOutOfEstimateClick}>
            <AddButton />
            <span>Добавить {isInsideSection ? "подраздел" : "раздел"}</span>
          </div>
        )}
        {(canSharing || canGroup) && <span className={styles.subtitle}>Действия над выбранными:</span>}
        {canSharing ? (
          <div className={styles.selectWrapper} onClick={emptyClickHandler}>
            <Select
              className={cn(styles.select, { [styles.disabledSelect]: !selectedCount || shareEstimateItemsIsLoading })}
              options={formattedContractors}
              placeholder="Передать подрядчику"
              disabled={!selectedCount || shareEstimateItemsIsLoading}
              isLoading={shareEstimateItemsIsLoading}
              onChange={selectSubmitHandler as any}
              classNameOptions={styles.optionBlock}
            />
          </div>
        ) : canGroup ? (
          <ButtonBase
            type="button"
            className={styles.button}
            primary
            medium
            onClick={handleOpenModal}
            disabled={isGroupButtonDisabled}
          >
            Сгруппировать
          </ButtonBase>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ProjectSharingBar);
