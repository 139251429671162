import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TabsEnum } from "components/pages/Building/enums";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import Actions from "shared/ui/controls/Actions/Actions";
import InputBase from "shared/ui/inputs/InputBase";

import ISection from "types/interfaces/Section";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./BuildingSectionEditName.module.scss";

interface IProps {
  section: ISection;
  activeTab?: keyof typeof TabsEnum;
  //TODO убрать
  onRefetch?: () => void;
}

const BuildingSectionEditName = ({ section, activeTab, onRefetch }: IProps) => {
  const objectId = useObjectId();
  const [isEdit, setIsEdit] = useState(false);
  const canEdit = section.is_default && activeTab === TabsEnum.plan;
  const [isPending, setIsPending] = useState(false);

  const [name, setName] = useState(section.name);

  useEffect(() => {
    setName(section.name);
    setIsPending(false);
  }, [section.name]);

  const onSave = () => {
    //TODO вынести в момент переписывания страницы обьекта по человечески
    setIsPending(true);
    axios
      .patch(`/building/${objectId}/estimate/draft/sections/${section.id}/`, { name })
      .then(() => {
        message.success(`Сохранено`);
        setIsEdit(false);
      })
      .catch((e: any) => {
        errorCatcher(e);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  if (canEdit) {
    return (
      <div className={styles.wrapper} key={String(isEdit)} onClick={isEdit ? stopEventPropagation : () => {}}>
        {!isEdit && (
          <span className={styles.title} title={name}>
            {name}
          </span>
        )}
        {isEdit && (
          <InputBase
            value={name}
            onChange={({ target: { value } }) => {
              setName(value);
            }}
            onEnter={onSave}
          />
        )}
        {!isEdit && (
          <Actions
            className={styles.actions}
            canEdit
            canRemove
            onEdit={() => setIsEdit(true)}
            onRemove={() => {
              axios
                .delete(`/building/${objectId}/estimateitem/${section.id}/delete_default/`)
                .then(() => {
                  message.success(`Удалено`);
                  onRefetch?.();
                })
                .catch(errorCatcher);
            }}
          />
        )}
        {isEdit && (
          <Actions
            canSave
            canRemove
            onSave={onSave}
            onRemoveDirectly={() => {
              setIsEdit(false);
              setName(section.name);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <span className={styles.title} title={name}>
      {name}
    </span>
  );
};

export default BuildingSectionEditName;
