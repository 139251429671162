import moment from "moment";

const nowMoment = moment();

export const generateDaysForLine = (year: number) => {
  const days = [];

  for (let m = 0; m < 12; m++) {
    const maxDay = moment().year(year).month(m).daysInMonth();
    for (let d = 1; d <= maxDay; d++) {
      const currentDay = moment().year(year).month(m).date(d);
      const day = currentDay.day();
      const dayElem: { day: number; month: number; weekend?: boolean; fullDate: string; today?: boolean } = {
        day: d,
        month: m,
        fullDate: currentDay.format("YYYY-MM-DD"),
      };

      if (nowMoment.isSame(currentDay, "day")) {
        dayElem.today = true;
      }

      if (day === 0 || day === 6) {
        dayElem.weekend = true;
      }

      days.push(dayElem);
    }
  }

  return days;
};
