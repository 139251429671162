import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTypedParams } from "utils/hooks/useTypedParams";
import { useUrlModule } from "utils/hooks/useUrlModule";

export const _TEMP_useBuildingPageUtils = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlModule = useUrlModule();
  const params = useTypedParams();
  let buildingId = params.objectId;

  return {
    buildingId,
    urlModule,
    dispatch,
    history,
  };
};
