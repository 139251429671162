import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { CURRENCY_ENUM } from "types/enums/CurrencyEnum";

import { transformDigitToFinancial } from "../../../../../utils/formatters/transformDigitToFinancial";

import { ReactComponent as InfoIcon } from "images/icons/info.svg";
import { ReactComponent as RubleIcon } from "images/icons/ruble.svg";
import { ReactComponent as TengeIcon } from "images/icons/tenge.svg";

import styles from "./ChartPopup.module.scss";

const TRANSFORM_OPTIONS = { withFloat: false, withCurrencySign: true };

function ChartPopup({
  title = "Прогресс выполнения ",
  type = "currency", //"info"|"currency"
  popupClassName,
  containerClassName,
  all = 0,
  completed = 0,
  accepted = 0,
  onHover,
  isLast,
  isWithIndexes = false,
  customLabels,
}) {
  const [isActive, setIsActive] = useState(false);

  const COLORS = ["#72E2E6", "#8FE52B", "#FFE86A"];

  const popup = useRef(null);
  const trigger = useRef(null);
  const [positioningCn, setPositioningCn] = useState("");

  const hoverHandler = () => {
    onHover && onHover();
    setIsActive(true);
    //TODO использовать нормальный поповер
    let positioningCnCandidate = "";
    const triggerXOffset = trigger.current?.getBoundingClientRect()?.left;
    const popupWidth = popup.current?.offsetWidth;
    if (popupWidth > triggerXOffset + 30) {
      positioningCnCandidate = cn(positioningCnCandidate, styles.right);
    }
    setPositioningCn(positioningCnCandidate);
  };

  return (
    <div className={cn(styles.container, containerClassName)} onMouseEnter={hoverHandler}>
      <div ref={trigger} className={styles[type]} style={isWithIndexes ? { marginRight: "-0.625rem" } : null}>
        {type === "currency" && (LOCALIZATION_CONFIG.currency === CURRENCY_ENUM.TENGE ? <TengeIcon /> : <RubleIcon />)}
        {type === "info" && <InfoIcon />}
      </div>
      {
        <div
          ref={popup}
          className={cn(styles.popup, popupClassName, positioningCn, {
            [styles.last]: isLast,
            [styles.hidden]: !isActive,
          })}
        >
          <p className={styles.title}>{title}</p>
          <div className={styles.pair}>
            <div className={styles.item}>
              <div className={styles.legend} style={{ background: COLORS[0] }} />
              <p className={styles.sum}>
                {type === "currency" ? (
                  <span>{transformDigitToFinancial(completed, TRANSFORM_OPTIONS)}</span>
                ) : (
                  completed
                )}
              </p>
              <p className={styles.fieldName}>{customLabels?.[0] ?? "Выполнено"}</p>
            </div>
            <div className={styles.item}>
              <div className={styles.legend} style={{ background: COLORS[1] }} />
              <p className={styles.sum}>
                {type === "currency" ? <span>{transformDigitToFinancial(accepted, TRANSFORM_OPTIONS)}</span> : accepted}
              </p>
              <p className={styles.fieldName}>Принято</p>
            </div>
          </div>
          <div className={cn(styles.item, styles.final)}>
            <p className={styles.sum}>
              {type === "currency" ? <span>{transformDigitToFinancial(all, TRANSFORM_OPTIONS)}</span> : all}
            </p>
            <p className={styles.fieldName}>{type === "currency" ? "Бюджет" : "Кол-во по смете"}</p>
          </div>
        </div>
      }
    </div>
  );
}

export default React.memo(ChartPopup);
