import React from "react";
import { useSelector } from "react-redux";

import {
  actingExpendituresSelector,
  actingSectionsSelector,
} from "redux/modules/common/building/journal/acting/selectors";

import { useCheckIsConstructingControl } from "features/constructingControl";
import ButtonBase from "shared/ui/controls/ButtonBase";

import styles from "./JournalActingSelectingBar.module.scss";

interface IProps {
  onSubmit: () => void;
  selectedCount: number;
  type: keyof typeof buttonLabels;
}

enum buttonLabels {
  "ks2" = "Сформировать КС-3",
  "estimate" = "Сформировать КС-2",
}

const JournalActingSelectingBar: React.FC<IProps> = ({ onSubmit, type, selectedCount }) => {
  const isConstrControl = useCheckIsConstructingControl();
  return (
    <div className={styles.bar}>
      <div>Выбрано: {selectedCount || "0"}</div>
      {!!selectedCount && !isConstrControl && (
        <ButtonBase secondary onClick={onSubmit}>
          {buttonLabels[type]}
        </ButtonBase>
      )}
    </div>
  );
};

export default JournalActingSelectingBar;
