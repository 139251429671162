import moment from "moment";
import React from "react";

import { aookApi } from "redux/modules/common/building/journal/aook/api";
import { aosrApi } from "redux/modules/common/building/journal/aosr/api";

import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";

interface IProps {
  objectId: any;

  act: any;
  detailedAook: any;
}

const AookDownloadButton = ({ objectId, act, detailedAook }: IProps) => {
  return (
    <DownloadButton
      onDownload={(ext) =>
        aookApi.downloadAook(
          objectId,
          act?.id,
          `АООК №${detailedAook?.number} от ${moment(detailedAook?.act_date).format("DD.MM.YYYY")}`,
          ext
        )
      }
      options={[
        { id: "xlsx", name: "Excel (.xls)" },
        { id: "docx", name: "Word (.docx)" },
        { id: "xml", name: "XML (.xml)" },
      ]}
    />
  );
};

export default AookDownloadButton;
