import cn from "classnames";
import React, { useRef, useState } from "react";

import { IOption } from "components/UI/atoms/Select";

import ButtonBase from "../ButtonBase";

import useOnClickOutside from "hooks/useOnClickOutside";

import download from "../../../../images/download.svg";

import styles from "./DownloadButton.module.scss";

export interface IProps {
  onDownload: (ext?: string) => void;
  className?: string;
  options?: IOption[];
}

const DownloadButton: React.FC<IProps> = ({ className, onDownload, options }) => {
  const isWithDropdown = !!options?.length;
  const [isOpen, setIsOpen] = useState(false);

  const optionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(optionsRef, () => {
    setIsOpen(false);
  });

  return (
    <div className={cn(className, styles.root)} ref={optionsRef}>
      <ButtonBase onClick={() => (isWithDropdown ? setIsOpen((p) => !p) : onDownload())} quaternary type="button">
        Скачать
        <img className={styles.downloadIcon} alt="Иконка скачать" src={download} />
      </ButtonBase>
      {isWithDropdown && isOpen && (
        <div className={styles.options}>
          {options.map((el) => (
            <div
              key={el.id}
              className={styles.option}
              onClick={() => {
                setIsOpen(false);
                onDownload(el.id as string);
              }}
            >
              {el.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(DownloadButton);
