//@ts-nocheck
import moment from "moment";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ADD_SELECTED_SUBSECTIONS,
  DELETE_SELECTED_SUBSECTIONS,
  selectedSubsectionsSelector,
} from "../../../../../redux/modules/common/building/process";

import { useUIIndicator } from "../../../../../hooks/uiIndicators/useUIIndicator";
import { AMOUNTS_VARIANTS } from "../../../../UI/_TODO/EstimateSection/components/Amounts/Amounts";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import BuildingSection from "../BuildingSection/BuildingSection";
import SectionPlanModal from "../SectionPlanModal/SectionPlanModal";
import { useSectionPlan } from "../SubSection/useSectionPlan";

import { TabsEnum } from "../../enums";

import { stopEventPropagation } from "../../../../../utils/helpers/stopEventPropagation";

const Section = ({
  section,
  canSharing,
  canCheck,
  activeTab,
  dateEnd,
  dateStart,
  filters,
  permissions,
  canAddPlan,
  canViewPlanData,
  dataTestId,
  refetchLsrPlan,
  onRefetch,
}) => {
  const dispatch = useDispatch();
  const { measure } = useFinanceMeasure();
  const selectedSubsections = useSelector(selectedSubsectionsSelector);

  const [isSectionPlanModalOpen, setIsSectionPlanModalOpen] = React.useState(false);

  const { planData, refetchSectionPlan } = useSectionPlan(section.id, canViewPlanData);

  const { isIndicatorDisplayed } = useUIIndicator({
    type: "confirm_expenditure_plan",
    data: { parent_section_id: section.id },
    customFilter: (indicator) =>
      !!indicator?.data?.year &&
      !!indicator?.data?.month &&
      moment()
        .year(indicator.data.year)
        .month(indicator.data.month - 1)
        .date(1)
        .isBetween(moment(dateStart), moment(dateEnd)),
  });

  const sectionPlanModalHandler = React.useCallback((e) => {
    e.preventDefault();
    stopEventPropagation(e);
    setIsSectionPlanModalOpen((prev) => !prev);
  }, []);

  const sectionModalCloseHandler = React.useCallback(() => {
    refetchSectionPlan();
    refetchLsrPlan?.();
    setIsSectionPlanModalOpen(false);
  }, [planData, refetchSectionPlan]);

  const checkboxHandler = (isChecked) => {
    if (isChecked) {
      dispatch({ type: ADD_SELECTED_SUBSECTIONS, payload: section.id });
    } else {
      dispatch({ type: DELETE_SELECTED_SUBSECTIONS, payload: section.id });
    }
  };

  const amounts = useMemo(
    () => ({
      all: section.indicators ? section.indicators.estimate_amount : 0,
      completed: section.indicators ? section.indicators.work_completed : 0,
      accepted: section.indicators ? section.indicators.amount_accepted : 0,
      invested: section.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.INLINE,
      measure,
      sumEstimateAmount: section.aggregation_data?.sum_estimate_amount ?? 0,
    }),
    [section.indicators, measure]
  );

  return (
    <>
      <BuildingSection
        activeTab={activeTab}
        section={section}
        isChecked={selectedSubsections.includes(section.id)}
        onCheck={checkboxHandler}
        amounts={amounts}
        isDisplayEstimateStateMark
        isDisplayAmounts
        canCheck={canCheck}
        isDisplayAddButton={canAddPlan}
        onAddButtonClick={sectionPlanModalHandler}
        sectionPlanData={planData}
        isShownPlanData={activeTab === TabsEnum.plan}
        dataTestId={dataTestId}
        isWithIndicator={activeTab === TabsEnum.plan && isIndicatorDisplayed}
        onRefetch={onRefetch}
      />
      {canAddPlan && (
        <div onClick={stopEventPropagation}>
          <SectionPlanModal
            isOpen={isSectionPlanModalOpen}
            onClose={sectionModalCloseHandler}
            sectionName={section.name}
            sectionId={section.id}
            isLsr
            sectionBudget={amounts.all}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(Section);
