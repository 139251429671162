import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getKsLists } from "redux/modules/common/building/documents/ksList/thunks";

import { useQueryParams } from "utils/hooks/useQueryParams";
import { useTypedParams } from "utils/hooks/useTypedParams";

export const _TEMP_useBuildingPageKs = () => {
  const dispatch = useDispatch();
  const { objectId } = useTypedParams();

  const ks2Id = useQueryParams("ks2Id");
  const ks3Id = useQueryParams("ks3Id");

  useEffect(() => {
    dispatch(getKsLists(objectId));
  }, [objectId]);

  return {
    ks2Id,
    ks3Id,
  };
};
