import cn from "classnames";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "react-textarea-autosize";

import { aookApi } from "redux/modules/common/building/journal/aook/api";
import {
  aookClosingPendingSelector,
  aookInvalidateKeySelector,
  aookWorksSelector,
  detailedAookSelector,
} from "redux/modules/common/building/journal/aook/selectors";
import {
  closeAook,
  deleteDocInAook,
  editAook,
  getDetailedAook,
  getWorksBySectionInAook,
} from "redux/modules/common/building/journal/aook/thunks";
import { IAook } from "redux/modules/common/building/journal/aook/types";
import { prepareDetailedAookForInitialForm } from "redux/modules/common/building/journal/aook/utils";
import { IAosrDoc } from "redux/modules/common/building/journal/aosr/types";
import {
  IAookPreviewInJournalExpenditure,
  IExpendituresInJournalFulfillment,
} from "redux/modules/common/building/journal/journalExecution/types";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import AosrDocumentAdd from "../../Aosr/AosrDocumentAdd/AosrDocumentAdd";
import AosrMaterials from "../../Aosr/AosrMaterials/AosrMaterials";
import { useAosrMaterialsV2 } from "../../Aosr/AosrMaterials/useAosrMaterialsV2";
import { useAosrDocs } from "../../Aosr/useAosrDoc";
import AookPermits from "../AookPermits/AookPermits";
import AookSelectAosrs from "../AookSelectAosrs/AookSelectAosrs";
import AookDownloadButton from "./ui/AookDownloadButton/AookDownloadButton";
import ConfirmModal from "entities/ConfirmationModal/ConfirmModal";
import { FormApi } from "final-form";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import { DisplayAxesAndMarks } from "widgets/AxesAndMarks";

import { MATERIAL_SOURCES, aookDocsTypesOptions } from "../../Aosr/constants";

import { composeFieldValidators, required } from "utils/formHelpers/validations";
import downloadFile from "utils/helpers/download";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import StatusConfirmedIcon from "images/icons/StatusConfirmedIcon";

import styles from "./AookExistingModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  sectionId: number;
  ticket: IExpendituresInJournalFulfillment["items"][0];
  activeAook: IAookPreviewInJournalExpenditure | null;
}

enum NextType {
  EXPENDITURE = "expenditure",
  GROUP = "group",
}

const EMPTY_ARRAY: any[] = [];

const AookExistingModal: FC<IProps> = ({ isOpen, onClose, ticket, sectionId, activeAook }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const ticketItem = ticket?.expenditure ?? ticket?.group ?? ticket?.linked;
  const act = activeAook;
  const detailedAook = useSelector(detailedAookSelector)[act?.id ?? -1];
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { docs: allDocs, startCreatingDoc } = useAosrDocs(detailedAook?.docs);
  const invalidateKey = useSelector(aookInvalidateKeySelector);
  const works = useSelector(aookWorksSelector)[sectionId];

  const [dateStart, setDateStart] = useState(detailedAook?.start_at);
  const [dateEnd, setDateEnd] = useState(detailedAook?.end_at);

  const [nextType, setNextType] = useState<NextType>(NextType.EXPENDITURE);
  const [nextId, setNextId] = useState<number | undefined>(undefined);
  const initialNextRef = useRef({ id: null, type: null });

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getWorksBySectionInAook(objectId, sectionId));
    if (!act) return;
    dispatch(getDetailedAook(objectId, act.id));
  }, [act, objectId, isOpen, invalidateKey, sectionId]);

  useEffect(() => {
    if (detailedAook && nextId === undefined) {
      let newNextType = NextType.EXPENDITURE;
      let newNextId;
      if (detailedAook.next_group) {
        newNextType = NextType.GROUP; /* @ts-ignore */
        newNextId = detailedAook.next_group.id;
      }

      if (detailedAook.next_expenditure) {
        newNextType = NextType.EXPENDITURE; /* @ts-ignore */
        newNextId = detailedAook.next_expenditure.id;
      }

      setNextType(newNextType);
      setNextId(newNextId); /* @ts-ignore */
      initialNextRef.current = { id: newNextId, type: newNextType };
    }
  }, [detailedAook, nextId]);

  const { materials, addMaterial, removeMaterial } = useAosrMaterialsV2(
    detailedAook?.all_materials || EMPTY_ARRAY,
    detailedAook?.materials,
    true
  );

  const submitHandler = async (values: IAook) => {
    dispatch(
      editAook(
        /* @ts-ignore */
        {
          ...values,
          materials: materials.filter((el) => !el.was_deleted).map((el) => el.id) || [],
        },
        objectId,
        act?.id!,
        { initialDocs: detailedAook?.docs },
        () => {
          setIsDisabled(true);
          setIsEditing(false);
        }
      )
    );
  };

  const removeAosrDoc = useCallback((index: number) => {}, []);

  const downloadHandler = useCallback(
    (document: IAosrDoc["listfiles"]) => {
      downloadFile(document[0]?.file_path, document[0]?.originalname);
    },
    [document]
  );

  const [deletingDoc, setDeletingDoc] = useState<number | null>(null);
  const openDeletingModal = (doc: IAosrDoc) => {
    setDeletingDoc(doc.id);
  };
  const closeDeletingModal = () => setDeletingDoc(null);
  const deleteDocHandler = () => {
    dispatch(deleteDocInAook(objectId, detailedAook?.id, deletingDoc!));
  };
  useEffect(() => {
    setDeletingDoc(null);
  }, [invalidateKey]);

  const isClosingPending = useSelector(aookClosingPendingSelector)[act?.id || ""];

  const resetForm = (form: FormApi) => {
    form.reset();
    /* @ts-ignore */
    setNextType(initialNextRef.current.type); /* @ts-ignore */
    setNextId(initialNextRef.current.id);

    initialNextRef.current = { id: null, type: null };
  };

  if (!act) return null;

  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.modal}>
      <Form
        mutators={{
          setNext: (args, state) => {
            const id = args[0];
            const name = args[1];

            const field = state.fields["next"];
            field.change(name);

            const selected = works.find((w) => w.id === args[0]);
            if (selected) {
              const newNextType = selected.expenditure_type === "work" ? NextType.EXPENDITURE : NextType.GROUP;
              setNextType(newNextType);
              setNextId(id);
            }
          },
        }}
        key={detailedAook?.id}
        onSubmit={(values) => {
          const preparedValues = {
            ...values,
          };

          if (nextType === NextType.GROUP) {
            preparedValues.next_group_id = nextId;
            preparedValues.next_expenditure_id = null;
            preparedValues.next_expenditure = null;
          }

          if (nextType === NextType.EXPENDITURE) {
            preparedValues.next_expenditure_id = nextId;
            preparedValues.next_group_id = null;
            preparedValues.next_group = null;
          }

          delete preparedValues.next_work_id;
          /* @ts-ignore */
          submitHandler(preparedValues);
        }}
        initialValues={prepareDetailedAookForInitialForm(detailedAook)}
        render={({ handleSubmit, form }) => (
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <header className={styles.header}>
              <div className={styles.headline}>
                <h2>
                  АООК №{detailedAook?.number} от {moment(detailedAook?.act_date).format("DD.MM.YYYY")}
                </h2>
                <AookDownloadButton detailedAook={detailedAook} act={act} objectId={objectId} />
              </div>
              <div className={styles.subtitle}>К работе:</div>
              <div className={styles.work}>{ticketItem?.name}</div>
            </header>
            <div className={styles.content}>
              <Field
                name="structure_name"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>К освидетельствованию предъявлены следующие конструкции</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />

              <Field
                name="project_doc"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Работы выполнены по проектной документации</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Номер, реквизиты чертежа, наименование проектной и/или рабочей документации"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
            </div>
            <div className={styles.content}>
              <Field
                name="start_at"
                render={() => (
                  <div className={styles.row}>
                    <h4>Срок выполнения работ</h4>
                    <CalendarRange
                      containerClassName={styles.range}
                      defaultDateStart={dateStart ?? detailedAook?.start_at}
                      defaultDateEnd={dateEnd ?? detailedAook?.end_at}
                      setDefaultDateEnd={setDateEnd}
                      setDefaultDateStart={setDateStart}
                      classNameSelect={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                      isTooLeft
                    />
                  </div>
                )}
              />
              <Field
                name="accordance"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Работы выполнены в соответствии с</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Наименование и структурные единицы технических регламентов, разделы проектной и/или рабочей документации"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
              {!!materials?.length && (
                <AosrMaterials
                  isOpen={isOpen}
                  materials={materials}
                  source={MATERIAL_SOURCES.AOOK}
                  canEdit={!isDisabled}
                  onRemoveMaterial={removeMaterial}
                  onAddMaterial={addMaterial}
                  isViewMode={isDisabled}
                />
              )}
            </div>
            {isDisabled ? (
              <>
                {!!allDocs?.length && (
                  <div className={cn(styles.doc, styles.docsHead)}>
                    <div>Наименование документа</div>
                    <div>Дата</div>
                  </div>
                )}
                {allDocs?.map?.((el, i) => (
                  <div className={styles.doc} key={el.id}>
                    <div>{el.title}</div>
                    <div>{moment(el.doc_date).format("DD.MM.YYYY")}</div>
                    <div className={cn(styles.actionCell, styles.download)}>
                      {el.listfiles?.length > 0 && <DownloadIcon onClick={() => downloadHandler(el.listfiles)} />}
                    </div>
                    <div className={styles.actionCell}>
                      <StatusConfirmedIcon />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.docsContainer}>
                {allDocs?.map((el, i) => (
                  <AosrDocumentAdd
                    index={i}
                    key={i}
                    remove={removeAosrDoc}
                    rowClassName={styles.docsRow}
                    isEditMode
                    onDirectlyDelete={openDeletingModal}
                    item={el}
                    typeOptions={aookDocsTypesOptions}
                  />
                ))}
                <ConfirmModal
                  title="Подтвердите удаление"
                  isOpen={!!deletingDoc}
                  onClose={closeDeletingModal}
                  action={deleteDocHandler}
                  variant="secondary"
                />
                <AddButton
                  onClick={startCreatingDoc}
                  text="Добавить"
                  textPosition="left"
                  className={styles.addButton}
                />
              </div>
            )}
            {isOpen && (
              <div className={styles.axes}>
                <DisplayAxesAndMarks
                  planOrFact="fact"
                  id={ticket?.expenditure?.facts_id?.[0]!}
                  workOrGroup={!!ticket?.group ? "group" : "work"}
                />
              </div>
            )}
            <div className={styles.content}>
              <AookSelectAosrs
                allHiddenworks={detailedAook?.all_hiddenworks}
                selectedHiddenworksIds={form.getState().values?.hiddenworks as number[]}
                isDisabled={isDisabled}
              />
              <AookPermits
                isDisabled={isDisabled}
                initialPartiallyPermit={String(form.getState()?.values?.permit_partially)}
              />
              <h3 className={styles.nextTitle}>Разрешается производство последующих работ</h3>
              <Field
                name="next"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Наименование работы</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Укажите наименование"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
              <Field
                name={`next_work_id`}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <label>Наименование работы по смете</label>
                    <Select
                      containerClassName={styles.select}
                      value={nextId}
                      onChange={(id, name) => {
                        form.mutators.setNext(id, name);
                        input.onChange(id);
                      }}
                      meta={meta}
                      options={works}
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                      placeholder={form.getState().values.next}
                    />
                  </div>
                )}
              />
              <Field
                name="additional"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <h4>Дополнительные сведения</h4>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Введите дополнительную информацию"
                      className={cn({ [styles.disabled]: isDisabled })}
                      disabled={isDisabled}
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
            </div>
            <div className={styles.heightHolder} />
            <BottomControls isExists={detailedAook?.status !== "close"} isDoubleBtns>
              {isEditing ? (
                <>
                  <ButtonBase
                    type="button"
                    secondary
                    onClick={() => {
                      setIsDisabled(true);
                      setIsEditing(false);
                      resetForm(form);
                    }}
                  >
                    Отменить
                  </ButtonBase>
                  <ButtonBase primary type="submit">
                    Сохранить
                  </ButtonBase>
                </>
              ) : (
                <>
                  <ButtonBase
                    secondary
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsDisabled(false);
                      setIsEditing(true);
                    }}
                  >
                    Редактировать
                  </ButtonBase>

                  {detailedAook?.status !== "close" && (
                    <ButtonBase
                      primary
                      type="button"
                      onClick={() => fileInput.current?.click()}
                      isLoading={isClosingPending}
                    >
                      Прикрепить АООК
                    </ButtonBase>
                  )}
                </>
              )}
            </BottomControls>
            <input
              type="file"
              ref={fileInput}
              className={styles.hideInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(closeAook(objectId, act?.id!, e.target.files?.[0]!));
              }}
            />
          </form>
        )}
      />
    </SliderModal>
  );
};

export default AookExistingModal;
