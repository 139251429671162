import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { DOCUMENTATION, PLAN, PROGRESS, TABS_IDS } from "../constants";
import { TabsEnum } from "../enums";

import { useUrlModule } from "utils/hooks/useUrlModule";

const _TEMP_useBuildingPageNavigation = ({
  isNeedToOpenPlan,
  permissions,
  buildingId,
  location,
  match,
}: {
  isNeedToOpenPlan?: boolean;
  permissions: any;
  buildingId: string;
  location: any;
  match: any;
}) => {
  const history = useHistory();
  const urlModule = useUrlModule();

  const getDefaultActiveTab = useCallback(() => {
    if (isNeedToOpenPlan && permissions.canViewPlan) {
      return PLAN;
    }
    if (permissions.canViewProgress) return PROGRESS;
    if (permissions.canViewPlan) return PLAN;
    if (permissions.canViewDrawings) return DOCUMENTATION;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings]);

  useEffect(() => {
    if (isNeedToOpenPlan) {
      if (!permissions.canViewPlan && urlModule === "objects") {
        history.replace(`/constructing/projects/${buildingId}/${location.search}`);
      }
    }
  }, [isNeedToOpenPlan, permissions, urlModule]);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");

    return TABS_IDS.find((id: string) => partOfSectionPath.includes(id)) || getDefaultActiveTab();
  }, [location.pathname, match.url]);

  const handleChangeActiveTab = useCallback(
    (tabId: string) => {
      history.push(`${match.url}/${tabId}`);
    },
    [match.url, history]
  );

  useEffect(() => {
    if (!activeTabId) handleChangeActiveTab(getDefaultActiveTab()!);
  }, []);

  useEffect(() => {
    if (permissions.canViewPlan && !permissions.canViewProgress) {
      if (activeTabId !== TabsEnum.plan) {
        handleChangeActiveTab(TabsEnum.plan);
      }
    }
  }, [permissions]);

  return {
    activeTabId,
    handleChangeActiveTab,
  };
};

export default _TEMP_useBuildingPageNavigation;
