import moment from "moment";
import React from "react";

import { aosrApi } from "redux/modules/common/building/journal/aosr/api";

import DownloadButton from "shared/ui/controls/DownloadButton/DownloadButton";

interface IProps {
  objectId: any;
  expenditureId: any;
  act: any;
  detailedAosr: any;
}

const AosrDownloadButton = ({ objectId, expenditureId, act, detailedAosr }: IProps) => {
  return (
    <DownloadButton
      onDownload={(ext) =>
        aosrApi.download(
          objectId,
          expenditureId as never,
          act?.id,
          `АОСР №${detailedAosr?.number} от ${moment(detailedAosr?.act_date).format("DD.MM.YYYY")}`,
          ext
        )
      }
      options={[
        { id: "xlsx", name: "Excel (.xls)" },
        { id: "docx", name: "Word (.docx)" },
        { id: "xml", name: "XML (.xml)" },
      ]}
    />
  );
};

export default AosrDownloadButton;
