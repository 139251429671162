import { _TEMP_useBuildingPageUIConfig } from "./_TEMP_useBuildingPageUIConfig";

import { _TEMP_useBuildingPageDates } from "./_TEMP_useBuildingPageDates";
import { _TEMP_useBuildingPageGroups } from "./_TEMP_useBuildingPageGroups";
import { _TEMP_useBuildingPageKs } from "./_TEMP_useBuildingPageKs";
import _TEMP_useBuildingPageNavigation from "./_TEMP_useBuildingPageNavigation";
import { _TEMP_useBuildingPageOutOfEstimate } from "./_TEMP_useBuildingPageOutOfEstimate";
import { _TEMP_useBuildingPageSections } from "./_TEMP_useBuildingPageSections";
import { _TEMP_useBuildingPageSharing } from "./_TEMP_useBuildingPageSharing";
import { _TEMP_useBuildingPageSubSections } from "./_TEMP_useBuildingPageSubSections";
import { _TEMP_useBuildingPageUtils } from "./_TEMP_useBuildingPageUtils";
import { _TEMP_useBuildingTabs } from "./_TEMP_useBuildingTabs";

//TODO временный файл чтоб уменьшить размер нечитаемого Building.tsx
export const _TEMP_useBuildingPage = {
  commonUtils: _TEMP_useBuildingPageUtils,
  tabs: _TEMP_useBuildingTabs,
  navigation: _TEMP_useBuildingPageNavigation,
  dates: _TEMP_useBuildingPageDates,
  sharing: _TEMP_useBuildingPageSharing,
  groups: _TEMP_useBuildingPageGroups,
  ks: _TEMP_useBuildingPageKs,
  sections: _TEMP_useBuildingPageSections,
  outOfEstimate: _TEMP_useBuildingPageOutOfEstimate,
  subSections: _TEMP_useBuildingPageSubSections,
  uiConfig: _TEMP_useBuildingPageUIConfig,
};
