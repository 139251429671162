import React, { useMemo } from "react";

import { DOCUMENTATION, PLAN, PROGRESS } from "../constants";

export const _TEMP_useBuildingTabs = (permissions: any, styles: any) => {
  return useMemo(() => {
    const res = [];
    if (permissions.canViewProgress) {
      res.push({
        text: "Прогресс",
        //TODO TabsEnum
        id: PROGRESS,
        className: styles.tab,
      });
    }
    if (permissions.canViewPlan) {
      res.push({
        text: "Планирование",
        id: PLAN,
        className: styles.tab,
      });
    }
    if (permissions.canViewDrawings) {
      res.push({
        text: "Документация",
        id: DOCUMENTATION,
        className: styles.tab,
      });
    }
    return res;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings]);
};
