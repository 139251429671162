import cn from "classnames";
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartActions } from "redux/modules/common/chart/actions";
import {
  chartActionsSelector,
  chartLoadersSelector,
  chartProjectIdsSelector,
  chartTreeCachedOpenSelector,
  chartTreeExpanderSelector,
  chartTreeFiltersSelector,
} from "redux/modules/common/chart/selectors";
import { askMoreChartProjects, toggleTreeOpen } from "redux/modules/common/chart/thunks";
import { IChartFilters, IChartTree } from "redux/modules/common/chart/types";

import { useRem } from "components/pages/Manufacturing/hooks/useRem";

import { ChartContext } from "../../Chart";
import ChartDateLine from "../ChartDateLine/ChartDateLine";
import ChartIntervalsRow from "../ChartIntervalsRow/ChartIntervalsRow";
import { ChartLockedSpacer } from "../ChartLockedSpacer/ChartLockedSpacer";
import ChartScrollFAB from "../ChartScrollFAB/ChartScrollFAB";
import ChartTreeBubbles from "../ChartTreeBubbles/ChartTreeBubbles";
import ChartTreeExpander from "../ChartTreeExpander/ChartTreeExpander";
import ChartTreeHeader from "../ChartTreeHeader/ChartTreeHeader";
import { FixedSizeTree as Tree } from "lib/vtree";
import { createPortal } from "react-dom";
import AutoSizer from "react-virtualized-auto-sizer";

import { CHART_TREE_LVL } from "../../constants";

import { useChartRunningLine } from "../../hooks/useChartRunningLine";

import { displayDateFilter } from "../../utils/displayDateFilter";
import { checkHasFilters } from "./ChartTree.utils/checkHasFilters";
import { checkSatisfyFilters } from "./ChartTree.utils/checkSatisfyFilters";

import styles from "./ChartTree.module.scss";

export interface IChartTreeItemProps {
  data: IChartTree;
  style: any;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const ChartTreeItem: React.FC<IChartTreeItemProps> = React.memo(({ data: tree, style, isOpen, setOpen }) => {
  const dispatch = useDispatch();
  const expander = useSelector(chartTreeExpanderSelector);
  const filters = useSelector(chartTreeFiltersSelector);
  const chartProjectIds = useSelector(chartProjectIdsSelector);

  const hasProjectLvlSkipFilter = filters?.skipBranchLvl?.[1];

  const clickHandler = useCallback(() => {
    setOpen(!isOpen);
    dispatch(toggleTreeOpen(tree._id, !isOpen));
    dispatch(chartActions.updateArrowHash());
  }, [tree._id, isOpen, setOpen]);

  const { containerRef } = useContext(ChartContext);
  const [container, setContainer] = useState(containerRef?.current);
  useEffect(() => {
    setContainer(containerRef?.current);
  }, [containerRef?.current]);

  const index = Math.ceil(style.top / style.height);

  useEffect(() => {
    if (tree.lvl !== 1 || chartProjectIds.length < 3 || tree.id != chartProjectIds[chartProjectIds.length - 2]) return;
    dispatch(askMoreChartProjects());
  }, []);

  if (!tree || index === 0 || (tree.lvl === CHART_TREE_LVL.PROJECT && hasProjectLvlSkipFilter)) return null;

  const isCheckboxVisible = isOpen !== undefined && tree.children.length > 0 && !filters?.skipBranchLvl?.[tree.lvl + 1];
  const isNumberVisible = !isCheckboxVisible && tree.lvl !== CHART_TREE_LVL.PROJECT && tree.number !== undefined;

  const intervalsBranchStyle = Object.assign({}, style, {
    left: "35%",
    // – style.height из-за rootNode, который всегда есть но не отображается
    top: `calc(${(style.top || 0) - ((hasProjectLvlSkipFilter ? 2 : 1) * style.height || 0)}px + 2.25rem)`,
    width: 0,
  });

  const branchElementStyle = Object.assign({}, style, {
    // – style.height из-за rootNode, который всегда есть но не отображается
    top: `${(style.top || 0) - ((hasProjectLvlSkipFilter ? 2 : 1) * style.height || 0)}px`,
  });

  return (
    <>
      <div
        style={branchElementStyle}
        className={cn(styles.branchElement, {
          [styles.bgLight]: index % 2 === 1,
        })}
      >
        <div
          className={cn(styles.branch, {
            [styles.branchChild]: tree.lvl === CHART_TREE_LVL.LSR,
            [styles.branchChildActive]: tree.lvl === CHART_TREE_LVL.LSR && tree.children.length > 0,
            [styles.branchExpenditure]: tree.lvl === CHART_TREE_LVL.SECTION,
            [styles.branchExpenditureChild]: tree.lvl === CHART_TREE_LVL.EXPENDITURE,
            [styles.branchExpenditureChildWithNumber]: isNumberVisible,
          })}
          onClick={clickHandler}
          data-chart-tree-uid={tree._id}
        >
          <div
            className={cn(styles.branchNameWrapper, {
              [styles.wDays]: expander?.days,
              [styles.wPeriod]: expander?.period,
            })}
          >
            {isCheckboxVisible && (
              <div
                className={cn(
                  styles.branchCheckbox,
                  {
                    [styles.branchCheckboxTrue]: !isOpen,
                  },
                  styles.branchCheckboxFalse
                )}
              />
            )}
            {isNumberVisible && (
              <span className={styles.branchNumber} title={tree.number}>
                {tree.number}
              </span>
            )}
            <div className={styles.branchName}>{tree.name}</div>
          </div>
          <div className={styles.branchBubbles}>
            <ChartTreeBubbles tree={tree} />
          </div>
          {expander && (
            <div className={styles.flexDaysPeriods}>
              {expander.days && (
                <div className={styles.days}>
                  <span>{tree.plan_duration || "-"}</span>
                </div>
              )}
              {expander.period && (
                <div className={styles.period}>
                  <span>
                    {Boolean(tree.plan_start && tree.plan_end)
                      ? `${displayDateFilter(tree.plan_start)} - ${displayDateFilter(tree.plan_end)}`
                      : "-"}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {container
          ? createPortal(
              <div style={intervalsBranchStyle} className={styles.intervalsBranch}>
                <ChartIntervalsRow tree={tree} isOpen={isOpen} />
              </div>,
              container
            )
          : null}
      </div>
    </>
  );
});

export interface IChartTreeProps {
  tree: IChartTree;
}

// @ts-ignore
const innerElementType = forwardRef(({ children, style, ...rest }, ref) => {
  const expander = useSelector(chartTreeExpanderSelector);
  const loaders = useSelector(chartLoadersSelector);
  const runningLineRef = useChartRunningLine();

  const treeCn = cn(styles.tree, {
    [styles.withPeriod]: !!expander,
    [styles.w7]: expander?.days,
    [styles.w22]: expander?.period,
  });
  return (
    // @ts-ignore
    <div ref={ref} {...rest} style={style} className={styles.wrapper}>
      <div ref={runningLineRef} className={styles.runningLine} />
      {/* @ts-ignore */}
      <ChartDateLine containerRef={ref} />
      <ChartTreeHeader />
      <div className={treeCn} style={{ height: `${style?.height}px` }}>
        {children}
        <ChartTreeExpander />
        <ChartScrollFAB variant="left" />
      </div>
      {loaders?.blurred ? <ChartLockedSpacer /> : null}
    </div>
  );
});

export interface IChartTreeItemProps {
  tree: IChartTree;
}

const getNodeDefaultOpenState = ({
  node,
  filters,
  forceOpenState,
  cachedOpen,
}: {
  node: IChartTree;
  filters: IChartFilters;
  cachedOpen: Record<string, boolean>;
  forceOpenState: boolean | undefined;
}): boolean => {
  if (node.lvl === CHART_TREE_LVL.ROOT) return true;
  if (forceOpenState === false) return false;
  if (node.lvl === CHART_TREE_LVL.PROJECT) return true;
  if (filters?.skipBranchLvl?.[CHART_TREE_LVL.PROJECT] && node.lvl === CHART_TREE_LVL.LSR) return true;
  if (forceOpenState === true) return true;
  return Boolean(cachedOpen?.[node._id]);
};

const ChartTree: React.FC<IChartTreeProps> = ({ tree }) => {
  const { containerRef } = useContext(ChartContext);
  const filters = useSelector(chartTreeFiltersSelector);
  const cachedOpen = useSelector(chartTreeCachedOpenSelector);
  const diagramActions = useSelector(chartActionsSelector);

  const getNodeData = (node: any, nestingLevel: number): any => ({
    data: {
      ...node,
      id: node.id.toString(),
      isLeaf: node.children.length === 0,
      isOpenByDefault: getNodeDefaultOpenState({
        node,
        filters,
        cachedOpen,
        forceOpenState: diagramActions.default_tree_open_state!,
      }),
      nestingLevel,
    },
    nestingLevel,
    node,
  });

  const { REM } = useRem();

  const treeWalker = useMemo(() => {
    const hasFilters = checkHasFilters(filters);
    if (hasFilters) {
      return function* treeWalkerWithFilters(): any {
        const root = getNodeData(tree, 0);
        yield root;
        //@ts-ignore
        function* exploreNode(parentMeta, nestingLevel) {
          for (let i = 0; i < parentMeta.node.children.length; i++) {
            const child = getNodeData(parentMeta.node.children[i], nestingLevel + 1);

            if (checkSatisfyFilters(child.node, filters)) {
              yield child;
            }

            yield* exploreNode(child, nestingLevel + 1);
          }
        }
        //@ts-ignore
        yield* exploreNode(root, 0);
      };
    } else {
      return function* treeWalker(): any {
        const skipBranchLvl = filters.skipBranchLvl || {};

        const root = getNodeData(tree, 0);
        yield root;

        while (true) {
          const parentMeta = yield;

          const skipChildren = parentMeta.nestingLevel !== 0 && skipBranchLvl[parentMeta.nestingLevel + 1] === true;

          if (!skipChildren) {
            for (let i = 0; i < parentMeta.node.children.length; i++) {
              const child = getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);

              yield child;
            }
          }
        }
      };
    }
  }, [tree, filters, diagramActions.default_tree_open_state]);

  if (!tree?._id) return null;

  return (
    <AutoSizer>
      {/* @ts-ignore */}
      {({ height, width }) => (
        <Tree
          treeWalker={treeWalker}
          itemSize={3 * REM}
          overscanCount={20}
          innerRef={containerRef}
          innerElementType={innerElementType}
          height={height}
          width={width}
        >
          {/* @ts-ignore */}
          {ChartTreeItem}
        </Tree>
      )}
    </AutoSizer>
  );
};

export default React.memo(ChartTree);
